<template>
  <Layout :loading="loading">
    <div v-if="currentCompany" class="container">
      <h1>Ajouter une entreprise liée à votre profil utilisateur</h1>
      <RegisterForm
        :submit="addCompanyToUser"
        :mail="username"
        :vat="currentCompany.vat"
        @afterSubmit="handleAfterSubmit"
      />
    </div>
  </Layout>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import RegisterForm from '@/components/Register/RegisterForm.vue';
import Layout from '@/components/common/Layout.vue';
import lowcoApi from '@/api/lowco-api';

import useAuthenticatedUser from '../composables/useAuthenticatedUser';
import useCurrentCompany from '../composables/useCurrentCompany';

export default defineComponent({
  name: 'CreateEnterprise',
  components: { RegisterForm, Layout },
  setup() {
    const router = useRouter();

    const { username, profileId } = useAuthenticatedUser();
    const { fetchCompanies, currentCompanyId, setCurrentCompanyId } = useCurrentCompany();

    const loading = ref(false);
    const currentCompany = ref(null);

    const addCompanyToUser = (company) => lowcoApi.addCompanyToUser(company, profileId.value);

    const handleAfterSubmit = async (companyId) => {
      await fetchCompanies();
      setCurrentCompanyId(companyId);
      router.push('/profile');
    };

    const fetchCurrentCompany = async () => {
      try {
        loading.value = true;
        const result = await lowcoApi.getMyEnterprise(currentCompanyId.value);

        currentCompany.value = result;
      } catch (err) {
        // Handle error
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchCurrentCompany();
    });

    return {
      username,
      addCompanyToUser,
      handleAfterSubmit,
      loading,
      currentCompany,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
